import React, { Component } from 'react';
import privacy from '../images/privacy_final.pdf';

export default class Privacy extends Component {
  render() {
    return (
      <>
        <iframe style={{ width: '100%', height: '100%' }} src={privacy} />
      </>
    );
  }
}
